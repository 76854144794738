:root {
    --toastify-color-success: #00b09a;
    --toastify-color-error: #e4573d;
    --toastify-toast-width: 25%;
    --toastify-font-family: 'Roboto', sans-serif;
}

@tailwind base;
@tailwind components;

html,
body,
div#root {
    @apply h-screen  min-h-screen;
    font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    @apply text-6xl;
}
h2 {
    @apply text-5xl;
}
h3 {
    @apply text-4xl;
}
h4 {
    @apply text-3xl;
}
h5 {
    @apply text-2xl;
}
h6 {
    @apply text-xl;
}

.button {
    @apply bg-gray-200  uppercase  font-bold  px-8  py-4  flex  justify-center  items-center;
}

.button.small {
    @apply px-4  py-2;
}

.button.red {
    @apply bg-bioetica-red  text-white;
}

.button.green {
    @apply bg-green-500  text-white;
}

.button:disabled {
    @apply bg-gray-200  text-bioetica-red  cursor-not-allowed;
}

.input {
    @apply w-full  rounded  bg-gray-200  px-6  py-4  text-xl;
}

.input-error {
    @apply bg-bioetica-red  px-2  py-1  text-white  text-sm  font-bold  italic;
}

.uploader {
    @apply w-full  h-full  border-4  border-dashed  border-green-500  rounded-lg  flex  items-center  justify-center  text-6xl  text-green-500  cursor-pointer;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

@tailwind utilities;
